import request from "@/util/request";


//登录
export const doUserLogin = function (params = {}) {
    return request.post("/user/login", params)
}

//退出
export const doLogOut = function () {
    return request.post("/user/logout")
}


//修改密码
export const changePassword = function (params = {}) {
    return request.post("/user/password/change", params)
}


//获取用户权限信息
export const gerUserRole = function () {
    return request.get("/menu/my")
}