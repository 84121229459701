
<template>
  <router-view />
</template>
<!-- <script>
import DevicePixelRatio from "./util/devicePixeIRatio"
export default {
  name: "App",
  data() {
    return {
    }
  },
  created() {
    new DevicePixelRatio().init()
  }
}
</script> -->

<style lang="scss" >
html,
body,
#app {
  margin: 0;
  padding: 0;
  width: 100%;
  min-width: 1280px;
  overflow: hidden
}

.el-pagination {
  margin-top: 21px;
}


.stateA {
  background-color: springgreen;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px
}

.stateB {
  background-color: red;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px
}
</style>
