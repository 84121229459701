import { createApp } from 'vue'


import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'

import App from './App.vue'
import './registerServiceWorker'
import router from '@/permission'
// import router from "@/router"
import store from './store'
import icons from "@/icons"


import Video from "video.js"
import 'video.js/dist/video-js.css'

import 'nprogress/nprogress.css'
import ElTableInfiniteScroll from "el-table-infinite-scroll";


const app = createApp(App)

app.provide('$video', Video);
app.use(ElementPlus, {
    locale: zhCn,
}).use(icons).use(ElTableInfiniteScroll).use(store).use(router).mount('#app')

// app.config.globalProperties.$video = Video
