import { createStore } from 'vuex'

import { gerUserRole } from "@/api/index"

export default createStore({
  state: {
    token: sessionStorage.getItem("token"),
    user: sessionStorage.getItem("user") ? JSON.parse(sessionStorage.getItem("user")) : {},
    userId: sessionStorage.getItem("userId"),
    scaleX: 1,
    scaleY: 1,
    router: []
  },
  getters: {
  },
  mutations: {
    setToken(state, token) {
      state.token = token;
      sessionStorage.setItem("token", token);
    },
    setUser(state, user) {
      state.user = user;
      sessionStorage.setItem("user", JSON.stringify(user));
    },
    setuserId(state, userId) {
      state.userId = userId;
      sessionStorage.setItem("userId", userId);
    },
    UPDATE_SCALE: (state, scale) => {
      state.scaleX = scale.x;
      state.scaleY = scale.y;
    },
    setRoutes: (state, data) => {
      state.router = data
    }
  },
  actions: {
    async vuexUserInfo({ commit }) {
      const res = await gerUserRole();
      commit('setRoutes', res)
    }
  },
  modules: {
  }
})
