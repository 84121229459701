import { createRouter, createWebHashHistory } from 'vue-router'
// import HomeView from '../views/HomeView.vue'
// import routes from "./router.js"

export const constantRoutes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    component: () => import('@/views/LoginView.vue'),
    name: 'login',
    hidden: true,
  },
  {
    path: '/404',
    name: '403',
    component: () => import('@/views/ErrorPage'),
    hidden: true
  },
  {
    path: "/:pathMatch(.*)*",
    component: () => import('@/views/ErrorPage'),
    hidden: true,
  },
]



const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes: constantRoutes
  // routes
})


export default router
