import axios, { AxiosError } from "axios";
import { ElMessage } from 'element-plus'
import NProgress from "nprogress";
import store from "@/store";
import router from '@/permission'


const ERROR_MSG = {
    ECONNABORTED: "请求超时,请稍后重试",
    ETIMEDOUT: "网络异常,请检查网络连接",
    ERR_NETWORK: "网络异常,请检查网络连接",
    DEFAULT: "请求失败,未发送"
}
const HTTP_MSG = {
    200: "服务器成功返回请求的数据。",
    201: "新建或修改数据成功。",
    202: "一个请求已经进入后台排队（异步任务）。",
    204: "删除数据成功。",
    400: "发出的请求有错误，服务器没有进行新建或修改数据的操作。",
    401: "用户没有权限（令牌、用户名、密码错误）。",
    403: "用户得到授权，但是访问是被禁止的。",
    404: "发出的请求是不存在的，服务器没有进行操作。",
    406: "请求的格式不可得。",
    410: "请求的资源被永久删除。",
    422: "当创建一个对象时，发生一个验证错误。",
    500: "服务器发生错误，请检查服务器。",
    502: "网关错误。",
    503: "服务不可用，服务器暂时过载或维护。",
    504: "网关超时。",
    DEFAULT: "服务器忙,请稍后重试"
}

let request = axios.create({
    baseURL: process.env.VUE_APP_REQUEST_URL,
    timeout: 10000,
    withCredentials: true,

})

request.interceptors.request.use(
    // 请求执行前 成功时拦截器回调方法
    function (config) {
        NProgress.start()
        config.headers["X-Access-Token"] = store.state.token;
        // console.log("工具请求拦截器")
        return config;
    },
    // 请求执行前 失败时拦截器回调方法
    function (error) {
        NProgress.done()
        return Promise.reject(error);
    }
)

request.interceptors.response.use(
    // 请求执行后 响应成功时拦截器回调方法
    function (response) {
        NProgress.done()
        // 请求成功后的业务数据处理
        let { data, code, errorMessage } = response.data;
        if (code < 400) {
            return data;
        } else if (code == 401) {
            ElMessage.error(errorMessage);
            sessionStorage.removeItem("user");
            sessionStorage.removeItem("token");
            sessionStorage.removeItem("userId");
            router.push('/login')
        } else {
            ElMessage.error(errorMessage);
            return null;
        }
    },
    // 请求执行后 响应失败时拦截器回调方法
    function (error) {
        NProgress.done()
        let msg = ""
        if (!(error instanceof AxiosError)) {
            msg = "程序运行错误请联系管理员"
        } else if (error.response) {
            msg = HTTP_MSG[error.response.status] || HTTP_MSG.DEFAULT;
        } else {
            msg = ERROR_MSG[error.code] || ERROR_MSG.DEFAULT;
        }
        ElMessage.error(msg)
        return Promise.reject(error);
    }
)


export default request;