<template>
    <div class="login" :style="transformStyle">
        <el-container>
            <el-aside :width="sideWidth + 'px'">
                <div>
                    <div class="logo-box" v-if="!collapse">
                        <img src="@/assets/imgs/JSFT1.png" alt="">
                        <div class="text_1">江苏省对外经贸股份有限公司</div>
                    </div>
                    <div v-else>
                        <img src="@/assets/imgs/JSFT1.png" alt="" style="width: 60px;margin-top: 20px;margin-bottom: 20px;">
                    </div>
                    <div class="zhedie">
                        <img src="@/assets/imgs/ICON折叠.png" alt="" @click="doZheDie()">
                    </div>
                </div>
                <el-menu active-text-color="#ffd04b" background-color="#061526" class="el-menu-vertical-demo"
                    :default-active="$route.path" text-color="#fff" router unique-opened :collapse="collapse"
                    :collapse-transition="false">
                    <template v-for="item in routes" :key="item.name">
                        <el-menu-item v-if="!item.hidden && item.name != 'setup' && item.name != 'log'" :index="item.path">
                            <template #default>
                                <el-icon v-if="item.children[0].meta.title == '监控台'">
                                    <img src="../assets/imgs/监控台icon.svg" alt="">
                                </el-icon>
                                <el-icon v-else-if="item.children[0].meta.title == '自动报警管理'">
                                    <img src="@/assets/imgs/自动报警icon.svg" alt="">
                                </el-icon>
                                <el-icon v-else-if="item.children[0].meta.title == '人工报警管理'">
                                    <img src="@/assets/imgs/人工报警.svg" alt="">
                                </el-icon>
                                <el-icon v-else>
                                    <img src="@/assets/imgs/编组5.svg" alt="">
                                </el-icon>
                                <span>{{ item.children[0].meta.title }}</span>
                            </template>
                        </el-menu-item>
                        <el-sub-menu v-else-if="!item.hidden || item.name == 'setup' || item.name == 'log'" :key="item.name"
                            :index="item.path">
                            <template #title>
                                <el-icon v-if="item.meta.title == '设置管理'">
                                    <img src="@/assets/imgs/设置.svg" alt="">
                                </el-icon>
                                <el-icon v-else>
                                    <img src="@/assets/imgs/日志.svg" alt="">
                                </el-icon>
                                <span>{{ item.meta.title }}</span>
                            </template>
                            <template v-for="childItem in item.children" :key="childItem.name">
                                <el-menu-item v-if="!childItem.hidden" :index="childItem.path">
                                    <template #default>
                                        <span>{{ childItem.meta.title }}</span>
                                    </template>
                                </el-menu-item>
                            </template>
                        </el-sub-menu>
                    </template>
                </el-menu>
            </el-aside>
            <el-container>
                <el-header>
                    <div class="title">
                        <span class="text_2">安全防盗系统</span>
                        <el-breadcrumb>
                            <el-breadcrumb-item v-for="(item, i) in $route.meta.breadcrumbs" :key="i">{{ item }}
                            </el-breadcrumb-item>
                        </el-breadcrumb>
                    </div>
                    <div class="username">
                        <el-avatar shape="square" :src="require('@/assets/imgs/icon/默认登陆头像.png')" />
                        <el-dropdown>
                            <span class="el-dropdown-link">
                                {{ user }}<el-icon class="el-icon--right"><arrow-down /></el-icon>
                            </span>
                            <template #dropdown>
                                <el-dropdown-menu>
                                    <el-dropdown-item @click="dialogVisible = true">修改密码</el-dropdown-item>
                                    <el-dropdown-item @click="logoutDialog = true">退出登录</el-dropdown-item>
                                </el-dropdown-menu>
                            </template>
                        </el-dropdown>
                    </div>
                </el-header>
                <el-main>
                    <RouterView></RouterView>
                </el-main>
            </el-container>
        </el-container>

        <el-dialog v-model="dialogVisible" title="修改密码" width="30%" center>
            <div class="userInfoForm">
                <el-form label-width="90px" :rules="rules" :model="passwordData" ref="ruleForm">
                    <el-form-item label="头像">
                        <img :src="require('@/assets/imgs/icon/默认登陆头像.png')" alt="">
                    </el-form-item>
                    <el-form-item label="账户名">
                        <el-input disabled v-model="user"></el-input>
                    </el-form-item>
                    <el-form-item label="原密码" prop="oldpassword">
                        <el-input v-model="passwordData.oldpassword" type="password" show-password></el-input>
                    </el-form-item>
                    <el-form-item label="新密码" prop="newpassword">
                        <el-input v-model="passwordData.newpassword" type="password" show-password></el-input>
                    </el-form-item>
                    <el-form-item label="确认密码" prop="repassword">
                        <el-input v-model="passwordData.repassword" type="password" show-password></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <template #footer>
                <span class="dialog-footer">
                    <el-button type="primary" @click="doUpdatePassword()">
                        确定
                    </el-button>
                    <el-button @click="cancelChangePas()">取消</el-button>

                </span>
            </template>
        </el-dialog>


        <el-dialog v-model="logoutDialog" width="30%">
            <template #header>
                <div>
                    <el-icon color="#faad14" size="18px" style="height: 21px; line-height: 21px;">
                        <InfoFilled />
                    </el-icon>
                    <span>确认弹窗</span>
                </div>
            </template>

            <div class="main">
                <span>是否确定退出本账号</span>
            </div>
            <template #footer>
                <span class="dialog-footer">
                    <el-button type="primary" @click="logout()">
                        确定
                    </el-button>
                    <el-button @click="logoutDialog = false">取消</el-button>

                </span>
            </template>
        </el-dialog>
    </div>
</template>
  
<script>
import { mapState } from "vuex";
import { doLogOut, changePassword } from "@/api/index.js";
import { ElMessage } from "element-plus";

export default {

    transformStyle: function () {
        this.$store.commit("scale/UPDATE_SCALE", {
            x: this.scaleX,
            y: this.scaleY,
        });
        return {
            transform: `scale(${this.scaleX}, ${this.scaleY})`,
            margin: `-${this.marginHeight}px -${this.marginWidth}px`,
        };
    },
    data() {
        const checkPwd = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请再次输入密码"));
            } else if (value !== this.passwordData.newpassword) {
                callback(new Error("两次输入密码不一致!"));
            } else {
                callback();
            }
        };
        return {
            username: "",
            passwordData: {
                oldpassword: "",
                newpassword: "",
                repassword: "",
            },
            scaleX: "",
            scaleY: "",
            marginWidth: "",
            marginHeight: "",
            collapse: false,
            sideWidth: 325,
            dialogVisible: false,
            logoutDialog: false,

            rules: {
                oldpassword: [
                    {
                        required: true,
                        message: "请输入原密码",
                        trigger: "blur",
                    },
                ],
                newpassword: [
                    {
                        required: true, message: "请输入新密码", trigger: "blur"
                    },
                ],
                repassword: [
                    { required: true, message: "请再次输入密码", trigger: "blur" },
                    {
                        validator: checkPwd,
                        required: true,
                        trigger: "blur",
                    },
                ],
            },
        };
    },
    computed: {
        ...mapState(["user"]),
        transformStyle: function () {
            // console.log(this.scaleX, this.scaleY);
            // console.log(this.marginHeight, this.marginWidth);

            return {
                transform: `scale(${this.scaleX}, ${this.scaleY})`,
                margin: `-${this.marginHeight}px -${this.marginWidth}px`,
            };
        },
        routes() {
            return JSON.parse(sessionStorage.getItem("role"));
        },

    },
    mounted() {
        this.listenResize();
        // window.addEventListener("wheel", this.disableScroll, { passive: false });
    },

    methods: {

        doZheDie() {
            this.collapse = !this.collapse;
            if (this.collapse) {
                this.sideWidth = 64;
            } else {
                this.sideWidth = 325;
            }
        },


        initScale() {
            let containerWidth = window.innerWidth;
            let containerHeight = window.innerHeight;
            //console.log(containerHeight, containerWidth);
            containerWidth = isNaN(containerWidth) ? 0 : containerWidth;
            containerHeight = isNaN(containerHeight) ? 0 : containerHeight;
            let defaultHeight = 1080;
            let defaultWidth = 1920;
            // sacle 缩放比例。
            let scalex = containerWidth / defaultWidth;
            let scaley = containerHeight / defaultHeight;
            this.scaleX = scalex;
            this.scaleY = scaley;
            // console.log("111", this.scaleX)
            // console.log("222", this.scaleY)
            if (this.scaleX > 1) {
                this.scaleX = 1
            }
            if (this.scaleY > 0.862) {
                this.scaleY = 0.862
            }
            let marginWidth = (defaultWidth * (1 - this.scaleX)) / 2;
            let marginHeight = (defaultHeight * (1 - this.scaleY)) / 2;
            this.marginWidth = marginWidth;
            this.marginHeight = marginHeight;
            // console.log("12312122", this.marginWidth);
            // console.log("2222222", this.marginHeight);

        },
        listenResize() {
            this.initScale();
            window.addEventListener("resize", () => {
                this.initScale();
            });
        },
        async logout() {
            let result = await doLogOut();
            if (result == null) return;
            ElMessage.success("退出成功");
            this.logoutDialog = false;
            sessionStorage.removeItem("user");
            sessionStorage.removeItem("token");
            sessionStorage.removeItem("userId");
            sessionStorage.removeItem("role");
            this.$router.push("/login");
        },

        async doUpdatePassword() {
            let params = {
                newPassword: this.passwordData.newpassword,
                oldPassword: this.passwordData.oldpassword,
                userId: Number(sessionStorage.getItem("userId"))
            }
            let result = await changePassword(params);
            if (result == null) return;
            ElMessage.warning("密码已修改，请重新登录!");
            sessionStorage.removeItem("user");
            sessionStorage.removeItem("token");
            sessionStorage.removeItem("userId");
            this.$router.push("/login");
            this.passwordData.newpassword = "";
            this.passwordData.oldpassword = "";
            this.passwordData.repassword = "";
            this.dialogVisible = false;
        },

        cancelChangePas() {
            this.passwordData.newpassword = "";
            this.passwordData.oldpassword = "";
            this.passwordData.repassword = "";
            this.dialogVisible = false;
            this.$refs.ruleForm.clearValidate();
        }
    },

};
</script>
  
<style lang="scss" scoped>
.login {
    position: absolute;
    width: 1920px;
    height: 1080px;
    background-size: cover;

    .el-header {
        width: 100%;
        height: 96px;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #ccc;

        .title {
            width: 208px;
            height: 54px;
            margin: 16px 0 0 20px;

            .text_2 {
                width: 108px;
                height: 32px;
                -webkit-text-stroke: 0.5px rgba(35, 35, 35, 1);
                overflow-wrap: break-word;
                color: rgba(35, 35, 35, 1);
                font-size: 24px;
                font-family: MicrosoftYaHei;
                text-align: left;
                line-height: 32px;
            }

            .el-breadcrumb {
                height: 29px;
                line-height: 29px;
                margin-top: 10px;
                font-size: 19px;
                width: 300px;
            }
        }

        .username {

            span {
                margin-left: 10px;
            }


            .el-avatar {
                width: 26px;
                height: 26px;
                margin-top: 27px;
            }

            .el-dropdown {

                margin: 27px 0 0 -7px;
                height: 26px;
                line-height: 26px;
                font-size: 18px;

                .el-dropdown-link {
                    cursor: pointer;
                    outline: none;
                }
            }
        }
    }

    .el-aside {
        height: 1440px;
        background-color: #061526;
        transition: all 0.2s linear 0s;

        .logo-box {
            background-color: #061526;
            height: 100px;
            width: 325px;

            img {
                margin: 15px 0 0 108px;
                width: 84px;
                height: 26px;

            }

            .text_1 {
                color: rgba(255, 255, 255, 1);
                font-size: 21px;
                font-family: MicrosoftYaHei;
                margin: 7px 11px 0 27px;
            }
        }

        .zhedie {
            width: 44px;
            height: 34px;
            background-color: #061526;
            cursor: pointer;

            img {
                margin-left: 24px;
            }
        }

        .el-menu {
            border: 0;
            background-color: #061526;

            :deep(.el-sub-menu) {

                .el-menu-item {
                    background-color: #020c16;

                }
            }

        }

        .el-menu-item.is-active {
            background-color: #409eff !important;
        }

        .el-menu-item:hover {
            background-color: #409eff !important;
        }

        .el-menu-item {
            font-size: 19px !important;
        }

        .el-sub-menu {
            font-size: 19px !important;
        }

        :deep(.el-sub-menu__title) {
            font-size: 19px !important;
        }
    }

    .el-main {
        padding: 20px;
        width: 100%;

        background: #FFFFFF;
        // border-radius: 5px;

        .el-breadcrumb {
            padding: 20px 0 30px 0;
        }
    }
}
</style>


