// 守卫文件
import router from '@/router'
import Store from "@/store"
import Layout from '@/layout'


const asyncRoutes = [
    {
        path: '/moniter',
        component: Layout,
        name: 'moniter',
        children: [
            {
                path: '/moniter',
                component: () => import('@/views/MoniterPage/MoniterPage.vue'),
                meta: {
                    title: "监控台",
                    breadcrumbs: ["监控台"],
                }
            }
        ]
    },

    {
        path: '/automatic',
        component: Layout,
        name: 'automatic',
        children: [
            {
                path: '/automatic',
                component: () => import('@/views/AutomaticPage/AutomaticPage.vue'),
                meta: {
                    title: "自动报警管理",
                    breadcrumbs: ["自动报警管理"],
                }
            }
        ]
    },
    {
        path: '/Manual',
        name: 'Manual',
        component: Layout,
        children: [
            {
                path: '/Manual',
                component: () => import('@/views/ManualPage/ManualPage.vue'),
                meta: {
                    title: "人工报警管理",
                    breadcrumbs: ["人工报警管理"],
                }
            }
        ],
    },
    {
        path: '/Online',
        name: 'Online',
        component: Layout,
        children: [
            {
                path: '/Online',
                component: () => import('@/views/OnlinePage/OnlinePage.vue'),
                meta: {
                    title: "在线巡查",
                    breadcrumbs: ["在线巡查"],
                }
            }
        ]
    },
    {
        path: '/setup',
        component: Layout,
        name: 'setup',
        meta: {
            title: '设置管理',
        },
        children: [
            // {
            //     path: '/setup/StoreManage',
            //     component: () => import('@/views/StoreManagePage/StoreMPage.vue'),
            //     name: 'StoreM',
            //     meta: {
            //         title: '仓库管理',
            //         breadcrumbs: ["设置管理", "仓库管理"]
            //     },
            // },
            // {
            //     hidden: true, //代表路由标题在菜单中是否隐藏  true: 隐藏 false: 不隐藏
            //     path: '/setup/attribute',
            //     component: () => import('@/views/AttributePage/AttributePage.vue'),
            //     name: 'StoreManage',
            //     meta: {
            //         title: '仓库管理',
            //     },
            // },
            // {
            //     path: '/setup/equipment',
            //     name: 'equipment',
            //     component: () => import('@/views/EquipmentManagePage/EquipmentManagePage.vue'),
            //     meta: {
            //         title: "设备管理",
            //         breadcrumbs: ["设置管理", "设备管理"]
            //     }
            // },
            // {
            //     path: '/setup/arrange',
            //     name: 'arrange',
            //     component: () => import('@/views/ArrangePage/ArrangePage.vue'),
            //     meta: {
            //         title: "布防撤防管理",
            //         breadcrumbs: ["设置管理", "布防撤防管理"]
            //     }
            // },
            // {
            //     path: '/setup/user',
            //     name: 'user',
            //     component: () => import('@/views/UserPage/UserPage.vue'),
            //     meta: {
            //         title: "用户管理",
            //         breadcrumbs: ["设置管理", "用户管理"]
            //     }
            // },
            // {
            //     path: '/setup/role',
            //     name: 'role',
            //     component: () => import('@/views/RolePage/RolePage.vue'),
            //     meta: {
            //         title: "角色管理",
            //         breadcrumbs: ["设置管理", "角色管理"]
            //     }
            // },
        ],
    },
    {
        path: '/log',
        name: 'log',
        meta: {
            title: '日志模块',
            icon: '',
        },
        component: Layout,
        redirect: '/log/loginlog',
        children: [
            // {
            //     path: '/log/loginlog',
            //     name: 'loginlog',
            //     component: () => import('@/views/LoginLogPage/LoginLogPage.vue'),
            //     meta: {
            //         title: "登录日志",
            //         breadcrumbs: ["日志模块", "登录日志"]
            //     }
            // },

            // {
            //     path: '/log/Operationlog',
            //     name: 'Operationlog',
            //     component: () => import('@/views/OperationLogPage/OperationLogPage.vue'),
            //     meta: {
            //         title: "操作日志",
            //         breadcrumbs: ["日志模块", "操作日志"]
            //     }
            // },
            // {
            //     path: '/log/alarm',
            //     name: 'alarm',
            //     component: () => import('@/views/AlarmLogPage/AlarmLogPage.vue'),
            //     meta: {
            //         title: "报警日志",

            //         breadcrumbs: ["日志模块", "报警日志"]
            //     }
            // },
            // {
            //     path: '/log/Patrol',
            //     name: 'Patrol',
            //     component: () => import('@/views/PatrolPage/PatrolPage.vue'),
            //     meta: {
            //         title: "巡查日志",
            //         breadcrumbs: ["日志模块", "巡查日志"]
            //     }
            // },
            // {
            //     path: '/log/report',
            //     name: 'report',
            //     component: () => import('@/views/ReportPage/ReportPage.vue'),
            //     meta: {
            //         title: "每日汇报",
            //         breadcrumbs: ["日志模块", "每日汇报"]
            //     }
            // },
        ],
    },
]

const SetUpObj = {
    hidden: true, //代表路由标题在菜单中是否隐藏  true: 隐藏 false: 不隐藏
    path: '/setup/attribute',
    component: () => import('@/views/AttributePage/AttributePage.vue'),
    name: 'StoreManage',
    meta: {
        title: '仓库管理',
    },
}
const asyncSetupRoutes = [{
    path: '/setup/StoreManage',
    component: () => import('@/views/StoreManagePage/StoreMPage.vue'),
    name: 'StoreM',
    meta: {
        title: '仓库管理',
        breadcrumbs: ["设置管理", "仓库管理"]
    },
},

{
    path: '/setup/equipment',
    name: 'equipment',
    component: () => import('@/views/EquipmentManagePage/EquipmentManagePage.vue'),
    meta: {
        title: "设备管理",
        breadcrumbs: ["设置管理", "设备管理"]
    }
},
{
    path: '/setup/arrange',
    name: 'arrange',
    component: () => import('@/views/ArrangePage/ArrangePage.vue'),
    meta: {
        title: "布防撤防管理",
        breadcrumbs: ["设置管理", "布防撤防管理"]
    }
},
{
    path: '/setup/user',
    name: 'user',
    component: () => import('@/views/UserPage/UserPage.vue'),
    meta: {
        title: "用户管理",
        breadcrumbs: ["设置管理", "用户管理"]
    }
},
{
    path: '/setup/role',
    name: 'role',
    component: () => import('@/views/RolePage/RolePage.vue'),
    meta: {
        title: "角色管理",
        breadcrumbs: ["设置管理", "角色管理"]
    }
}]


const asyncLogRoutes = [
    {
        path: '/log/loginlog',
        name: 'loginlog',
        component: () => import('@/views/LoginLogPage/LoginLogPage.vue'),
        meta: {
            title: "登录日志",
            breadcrumbs: ["日志模块", "登录日志"]
        }
    },

    {
        path: '/log/Operationlog',
        name: 'Operationlog',
        component: () => import('@/views/OperationLogPage/OperationLogPage.vue'),
        meta: {
            title: "操作日志",
            breadcrumbs: ["日志模块", "操作日志"]
        }
    },
    {
        path: '/log/alarm',
        name: 'alarm',
        component: () => import('@/views/AlarmLogPage/AlarmLogPage.vue'),
        meta: {
            title: "报警日志",

            breadcrumbs: ["日志模块", "报警日志"]
        }
    },
    {
        path: '/log/Patrol',
        name: 'Patrol',
        component: () => import('@/views/PatrolPage/PatrolPage.vue'),
        meta: {
            title: "巡查日志",
            breadcrumbs: ["日志模块", "巡查日志"]
        }
    },
    {
        path: '/log/report',
        name: 'report',
        component: () => import('@/views/ReportPage/ReportPage.vue'),
        meta: {
            title: "每日汇报",
            breadcrumbs: ["日志模块", "每日汇报"]
        }
    },

]

router.beforeEach((to, from, next) => {
    const token = sessionStorage.getItem('token');
    if (!token && to.fullPath !== '/login') next({ name: 'login' })
    if (token && Store.state.router.length === 0) {
        Store.dispatch('vuexUserInfo').then(() => {
            const ruleArr = Store.state.router.map(item => item.name)
            const ruleArr1 = Store.state.router.filter(item => item.children)
            if (ruleArr1.length > 0) {
                for (let i = 0; i < ruleArr1.length; i++) {
                    if (ruleArr1[i].name == 'setup') {
                        const childName = ruleArr1[i].children.map(item => item.name)
                        const setupRule = asyncSetupRoutes.filter(item => childName.includes(item.name));
                        if (childName.includes('StoreM')) {
                            setupRule.push(SetUpObj)
                        }
                        asyncRoutes[4].children = setupRule;
                    }
                    if (ruleArr1[i].name == 'log') {
                        const childName1 = ruleArr1[i].children.map(item => item.name)
                        const logRule = asyncLogRoutes.filter(item => childName1.includes(item.name));
                        asyncRoutes[5].children = logRule;
                    }
                }
            }
            const routerRule = asyncRoutes.filter(item => ruleArr.includes(item.name));
            sessionStorage.setItem("role", JSON.stringify(routerRule))
            routerRule.forEach(item => {
                router.addRoute(item);
            })
            next({ ...to, replace: true })
        })
    } else {
        next()
    }
});


export default router